/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';

const FreelanceWordpressDeveloperPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={location}
        >
            <SEO
                title="Freelance WordPress Developer"
                description="I'm a reliable Freelance WordPress Developer with over 10 years worth of expertise. You can hire me to help with your WordPress project today."
                imageAlt="Freelance WordPress Developer"
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="Freelance WordPress Developer, Tom Hirst"
                imageCaption="Freelance WordPress Developer, Tom Hirst"
            >
                <h1>Are you looking for a freelance WordPress developer?</h1>
                <p><strong>Do you need:</strong></p>
                <ul>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A senior WordPress expert with over 10 years experience?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A reliable, full stack freelance WordPress developer
                        comfortable with custom, advanced and enterprise scale work?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A freelance WordPress developer who can help with Gutenberg, the REST API
                        and using WordPress as a headless CMS?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Guaranteed, professional development from someone delivering successful
                        projects for start ups, medium, large and global businesses, big brands
                        and WordPress VIP agencies?
                    </li>
                </ul>
                <p>
                    <strong>You&apos;re in the right place.</strong> Here&apos;s what
                    you can do next:
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#keep-reading" icon="arrow-down" text="Keep reading" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="keep-reading">What can an expert Freelance WordPress Developer do for you?</h2>
                <p>Common scenarios where I deliver successful projects:</p>
                <ol>
                    <li>
                        Your business needs to the point, technical WordPress advice
                        in the early stages of your project.
                    </li>
                    <li>
                        You need a freelance WordPress developer to work alongside a designer to
                        plan the best approach for your project.
                    </li>
                    <li>
                        Design, UX and UI work for your WordPress website is already finished and
                        you need an experienced full stack WordPress developer to build the theme.
                    </li>
                    <li>
                        You require a WordPress plugin or custom 3rd party integration to be
                        developed for your website.
                    </li>
                    <li>
                        You&apos;re looking to launch an eCommerce project on WordPress using
                        WooCommerce and need a professional to code it.
                    </li>
                    <li>
                        You want to use WordPress as a headless content management system,
                        via the REST API, for a detached front end using React, Next.js or
                        a static site generator like Gatsby with GraphQL.
                    </li>
                    <li>
                        You&apos;d like to use WordPress in an advanced way and you need
                        someone to advise on the project and see it through development.
                    </li>
                </ol>
                <p>If one of these situations describes your needs, you&apos;re in the
                    right place. You can <a href="#hire-me">hire me</a> to help today.
                </p>
                <h2>Why hire Tom Hirst as your Freelance WordPress Developer?</h2>
                <p>
                    If your WordPress solution is not up to scratch, it could affect
                    your business and its profits.
                </p>
                <p>
                    Whether you&apos;re thinking about creating something on the WordPress platform
                    or you&apos;re running WordPress already, you&apos;ll need an expert freelance
                    WordPress developer to oversee your project.
                </p>
                <p>
                    Working in the WordPress ecosystem for over 10 years, I&apos;ve seen some
                    real horror stories.
                </p>
                <p>
                    Why hire a WordPress expert to rectify sub-par development when you can
                    get it right first time?
                </p>
                <p>
                    Based in the UK, I have a decade&apos;s worth of experience
                    delivering successful projects for start ups, medium-large
                    businesses, and big brands worldwide.
                </p>
                <p>
                    WordPress VIP agencies work with me on an ongoing basis to produce
                    enterprise level, custom developments.
                </p>
                <p>
                    I develop full stack, bespoke WordPress solutions with
                    modern tooling and processes, using HTML, CSS, SCSS, PHP, JavaScript and React.
                </p>
                <p>
                    I&apos;m a good communicator, reliable, autonomous, pragmatic and happy to
                    project manage myself and/or a wider team.
                </p>
                <p>
                    I want to partner with you on your WordPress project for the long term.
                    From planning, through to development, go live and further improvements.
                </p>
                <p>
                    I pride my self on my output and I&apos;m confident
                    in its quality. That&apos;s why I guarantee my work.
                </p>
                <p>
                    Any issues that arise within your project&apos;s
                    scope, for 6 months after it goes live, I will fix as part of the original cost.
                </p>
                <h2 id="hire-me">Ready to start a project together?</h2>
                <p>
                    I&apos;d love to work with you. Fill out the form below to tell me more:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I've got a WordPress project that needs delivering professionally, on time and on budget."
                    pathname={location && location.pathname}
                />
                <h3>You&apos;ll be in good company</h3>
                <p>I&apos;ve worked on WordPress development projects for:</p>
                <ClientList />
                <h3>Kind words from people who have hired me</h3>
                <div className="blockquote-list">
                    <Blockquote
                        quote="I don't say this lightly, but Tom is (by some distance) the best freelance developer
              I have ever worked with."
                        cite="Chay Hunter"
                        imageName="ch-headshot.jpg"
                        imageAlt="Chay Hunter"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Frankly speaking, you want developers like Tom on your team."
                        cite="Brian Frolo"
                        imageName="bf-headshot.jpg"
                        imageAlt="Brian Frolo"
                        largeQuote
                    />
                    <Blockquote
                        quote="I'd very much recommend Tom to other agency owners."
                        cite="Angela Roche"
                        imageName="ar-headshot.jpg"
                        imageAlt="Angela Roche"
                        largeQuote
                    />
                    <Blockquote
                        quote="I engaged with Tom as I wanted an efficient and experienced WordPress developer, and that is exactly what we got."
                        cite="Paal Kristian Søberg"
                        imageName="ps-headshot.jpg"
                        imageAlt="Paal Kristian Søberg"
                        mediumQuote
                    />
                    <Blockquote
                        quote="Working with Tom has been a great experience. I would recommend Tom and will continue to
              work with him on future projects."
                        cite="Karen Gee"
                        imageName="kg-headshot.jpg"
                        imageAlt="Karen Gee"
                        mediumQuote
                    />
                </div>
                <h2>Let&apos;s get started today!</h2>
                <p>
                    Your search for a reliable freelance WordPress developer is over!
                    Head back up to the form, fill me in on your project and
                    let&apos;s get started today.
                </p>
                <a className="button is-green has-icon" href="#hire-me">
                    Take me back to the form
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

FreelanceWordpressDeveloperPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

FreelanceWordpressDeveloperPage.defaultProps = {
    location: {},
};

export default FreelanceWordpressDeveloperPage;
